.benefits-background{
    background-color: whitesmoke;
    overflow: auto;
}


.benefits-text{
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    color:teal;
    text-align: center;
    margin-top: 30px;
   
}

.center-grid{
    display: flex;
    justify-content: center;
   
}

.benefits-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 80%;
    align-items: center;
    margin-top: 90px;
   
  }
  
  @media (max-width: 768px) {
    .benefits-grid{
      grid-template-columns: 1fr;
      height: auto;
      width:90%;
      padding-bottom: 10px;
      margin-top: 50px;
    }
  }

  @media (max-width: 500px) {
    .benefits-grid {
      grid-template-columns: 1fr;
      height: auto;
      padding-bottom: 50px;
    }
  }


.benefits-card-col{
    height:auto;
    padding: 10px;
    color:#333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.benefits-title-text{
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-top: 50px;
}

.benefits-card-col-text{
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
  
}

@media(max-width:768px){
  .benefits-card-col-text{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    
  }
}
.navigation {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 1rem 0rem;
  background-color: whitesmoke;
  color: black;
  z-index: 5;
}



.shadow-navigation {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 1rem 0rem;
  background-color: #fff;
  color: black;
  z-index: 5;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

@media (max-width: 500px) {
  .shadow-navigation{
    height:60px;
  }
}

.logo {
  text-decoration: none;
  color:teal;
  font-size: 1.8rem;
  margin-left: 1rem;
  font-weight: bolder;
  font-style: italic;
}

.logo:hover{
  text-decoration: underline;
  color:teal;
}

@media (max-width: 769px) {
  .logo {
    font-size: 1.3rem;
  }
}
.navigation-menu {
 width:90%;
 display: flex;
justify-content: flex-end;
margin-right: 20px;
align-items: center;
margin-top: 10px;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
   height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li .nav-link {
    color: black;
    width: 100%;
    padding: 1rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li .nav-link {
  text-decoration: none;
  display: block;
  width: 100%;
  color: rgb(35, 31, 31);
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  
  
}

.navigation-menu li .focused-nav-link {
  text-decoration: none;
 display: block;
  width: 100%;
  font-style: normal;
  font-size: 23px;
  font-weight: 500;
  color:teal;
 
}
@media (max-width: 768px) {
 .focused-nav-link {
    color:teal;
    border-bottom: none;
    padding: 1rem 0;
  }
}
.navigation-menu li .nav-link:hover {
  color:teal;
}

.menu-button {
  border: 0;
  height: 40px;
  width: 45px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}


@media screen and (max-width: 768px) {
  .menu-button {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu.expanded ul {
    display: block;
  }
}

.auth-button {
  width:150px;
  cursor: pointer;
  background-color:teal;
  border:none;
  color:#fff;
  font-size: 17px;
  padding:10px;
  border-radius: 2px;
  font-weight: 700;
  font-weight: bold;
}

.auth-button:hover{
  background-color: rgb(32, 99, 99);
  text-decoration: underline;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .auth-button {
    /*display: none;*/
  }
}

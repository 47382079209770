
    .center-pricing-cards-grid{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        margin-top: 200px;
    }
    .pricing-cards-grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width:90%;
        place-items: center;  
          
    }

    @media(max-width:768px){
        .pricing-cards-grid{
            width:90%;
        }
    }

    @media  (max-width:900px) {
        .pricing-cards-grid{
            grid-template-columns: 1fr;
        }
    }

    .pricing-card-col{
       width:100%;
       max-width: 500px;
       margin-top: 50px;
       
    }

    
    .pricing-content{position:relative;}
    .pricing_design{
        position: relative;
        margin: 0px 15px;
        padding-bottom: 70px;
    }
    .pricing_design .single-pricing{
        background:teal;
        padding: 60px 40px;
        border-radius:30px;
        box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
        position: relative;
        z-index: 1;
    }
    .pricing_design .single-pricing:before{
        content: "";
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 18px 18px 190px 18px;
        border: 1px solid #eee;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    .price-head{}
    .price-head h2 {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 600;
    }
    .price-head h1 {
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 5px;
    }
    .price-head span{}
    
    .single-pricing ul{list-style:none;margin-top: 30px;}
    .single-pricing ul li {
        line-height: 36px;
    }
    .single-pricing ul li i {
        background: teal;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        font-size: 11px;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
    }
    .pricing-price{}
    
    .price_btn {
        background: teal;
        padding: 10px 30px;
        color: #fff;
        display: inline-block;
        margin-top: 20px;
        border-radius: 2px;
        text-decoration: none;
        cursor: pointer;
    }
    .price_btn:hover{ 
        background-color: rgb(32, 99, 99);
        text-decoration: underline;
        color: #fff;
    }
    a{
    text-decoration:none;    
    }
    
    .section-title {
        margin-bottom: 60px;
    }
    .text-center {
        text-align: center!important;
    }
    
    .section-title h2 {
        font-size: 45px;
        font-weight: 600;
        margin-top: 0;
        position: relative;
        text-transform: capitalize;
    }
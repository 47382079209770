.layout-background{
    width: 100%;
    min-height: 100vh;
    column-gap: 1rem;
    background-color:whitesmoke;
}

@media (max-width: 800px) {
    .layout-background{
    min-height: 100vh;
    height: 100%;
    }
}

.dashboard-toggle-wrapper{
    width:100%;
   
}

@media (min-width:800px) {
    .dashboard-toggle-wrapper{
        display: none;
    }
}

@media (max-width: 800px) {
    .layout-background{
    min-height: 100vh;
    height: 100%;
    }
}

.layout-grid{
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: auto;
    width: 100%;
    top: 0px;
}

@media (max-width: 800px) {
    .layout-grid{
    grid-template-columns: 1fr;
    }
}



.left-column{
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 600vh;
  top: 0px;
  width: 220px;
 
}

@media (max-width: 800px) {
   .left-column{
    display: none;
   }
}

.right-column{
    flex-direction: column;
    display: flex;
    overflow: hidden;
    top: 0px;
    position: relative;
}













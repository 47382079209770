.dashboard-page-wrapper{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0px;
    width:100%;
    padding-left: 30px;
    overflow: hidden;
   
}

@media(max-width: 768px) {
    .dashboard-page-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 0px;
    }

}

.dashboard-headline{
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    margin-top: 100px;
    
}

@media(max-width: 768px) {
    .dashboard-headline{
        font-size: 30px;
        font-weight: bold;
        margin-top: 100px;
        
    }

}


.create-spreadsheet-form-container{
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    background-color: whitesmoke;
    border: 0.2px solid #333;
    border-radius: 10px;
    padding:20px;
}

@media(min-width: 768px) {
    .create-spreadsheet-form-container{
        width:85%;
    }

}

@media(max-width: 500px) {
    .create-spreadsheet-form-container{
        width:90%;
    }
}






.url-input{ 
    width: 95%;
    padding-left: 20px;
    font-size: 19px;
    height: 40px;
    border-radius: 5px;
    border:1px solid #3333;
}

@media screen and (max-width: 768px) {
  .url-input{ 
  font-size: 15px;
}
 }

.url-input:focus {
  outline: none;
}

.create-button{
    background-color: teal;
    color:#fff;
    width:80px;
    height:44px;
    border: none;
    border-radius:5px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: bold;
   /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
}
  

.dashboard-input-header{
font-size: 16px;
color:#333;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
}
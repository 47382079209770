.documentation-page-wrapper{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0px;
        width:100%;
        padding-left: 30px;
        overflow: hidden;
        
       
        
       
    }
    
    @media(max-width: 500px) {
        .documentation-page-wrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-left: 10px;
        }
    
    }

    .documentation-headline{
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
        margin-top: 150px;
        color: teal;
        width: 50%;
        
    }
    
    @media(max-width: 768px) {
        .documentation-headline{
            font-size: 25px;
            font-weight: bold;
            margin-top: 100px;
            width:90%;
            
        }
    
    }

.documentation-text-wrapper{
    width: 50%;
    height: auto;
}

@media(max-width: 768px) {
    .documentation-text-wrapper{
        width: 90%;
        
    }
}

.documentation-text{
    font-weight: 500;
    font-size: 20px;
}


.documentation-text-subheadline{
    font-weight: 700;
    font-size:40px;
    color: teal;
    margin-top: 50px;

}

@media(max-width: 768px) {
    .documentation-text-subheadline{
        font-size: 25px;
        font-weight: bold;
        width:90%;
        
    }

}

.documentation-list{
    list-style:circle;
}

.documentation-list li{
    font-weight: 500;
    margin-top: 10px;
    font-size: 19px;
}
.hero-section {
    height: 500px;
    background-color:whitesmoke;
    margin-top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  
  @media (max-width: 768px) {
    .hero-section{
      height: auto;
    }
  }

.headline-wrapper{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left:10px;
    padding-right:10px;
    
}

@media (max-width: 768px) {
  .headline-wrapper{
   width:80%;
   margin-top:50px;
  }
}

.headline{
    color: teal;
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;  
    
}

@media (max-width: 768px) {
  .headline{
   font-size: 40px;
  }
}

.subheadline-wrapper{
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left:10px;
  padding-right:10px;
  width:50%; 
  margin-top: 70px;
}

@media (max-width: 769px) {
  .subheadline-wrapper {
    width:80%;
    margin-top: 50px;
  }
}

.subheadline{
  font-size: 25px;
  color:#333;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

@media (max-width: 769px) {
  .subheadline{
    font-size: 15px;
    font-weight: 600;
  }
}

.call-to-action-wrapper{
  margin-top: 70px;
}

@media(max-width: 768px) {
  .call-to-action-wrapper{
    margin-top: 20px;
  }
}

.call-to-action-button{
  background-color: teal;
  color:#fff;
  width:300px;
  height:60px;
  border:none;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.call-to-action-button:hover{
  background-color: rgb(32, 99, 99);
  text-decoration: underline;
  color: #fff;
}

@media (max-width: 769px) {
  .call-to-action-button{
    width:270px;
    height:50px;
  }
}

.no-credit-card-required-text{
  font-size: 11px;
  font-weight:bold;
  margin-top:15px;
  color:#333;
  display: flex;
}


.sheeturl-form-wrapper{
  width:100%;
  display: flex;
  justify-content: center;
}





  
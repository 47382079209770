.dashboard-toggle-wrapper{
    width:100%;
    position:sticky;
   /* overflow: hidden;*/
    top:0;
}

@media (min-width:800px) {
    .dashboard-toggle-wrapper{
        display: none;
    }
}

.dashboard-toggle-button{
    border: 0;
    height: 40px;
    width: 45px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    top: 50%;
    right: 25px;
    
    
  
}

.navdrawer-wrapper{
  width: 100%;
  border-radius: 0 10px 10px 0;
  height: 100vh;
 /* background-color:rgb(18, 21, 33);*/
 background-color: rgb(27, 61, 61);
  color: #fff;
  top: 0; 
  bottom: 0;
  transition: 0.5s;
  position: sticky;
  overflow: hidden;
 
}

@media screen and (min-width: 800px) {
  .navdrawer-wrapper{
    display: none; /* Hide on small screens */
  }

}

.usage-list-item-wrapper{
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
    background-color: whitesmoke;
    border: 0.2px solid #333;
    border-radius: 5px;
    padding:10px;
    padding-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media(max-width: 768px) {
    .usage-list-item-wrapper{
        width:85%;
        overflow:auto;
    }
}

@media(max-width: 820px) {
    .usage-list-item-wrapper{
        width:85%;
        overflow:auto;
    }
}

@media(max-width: 500px) {
    .usage-list-item-wrapper{
        width:90%;
    }
}

.usage-list-item-title-wrapper{
display: flex;
justify-content: flex-start;
padding-left: 10px;
}



.usage-list-item-title{
    font-size: 30px;
    font-weight: bold;
    color:#4a3e56;
}

@media(max-width: 500px) {
    .usage-list-item-title{
        font-size: 20px;
    }
}

.usage-message-text-wrapper{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}

@media(max-width: 500px) {
    .usage-list-item-title-wrapper{
        text-align: center;
        
    }
}
.example-background{
    background-color: whitesmoke;
    width:100%;
    overflow: auto;
    padding-bottom: 70px;
   
}

@media(max-width:768px){
  .example-background{
    padding-bottom: 20px;
  }
}

.example-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    align-items: center;
    margin-top: 60px;
  }
  @media (max-width: 768px) {
  .example-grid {
    grid-template-columns: 1fr;
    height: auto;
    padding-bottom: 50px;
    width: 95%;
    max-width: 500px;
    margin-top: 10px;
  }
}

  .example-col-1 {
    overflow: hidden;
    border-radius: 15px;
    height: 600px;
   
  }


  @media (max-width: 458px) {
    .example-col-1 {
     height: 300px;
      
    }
  }

  @media (max-width: 768px) {
    .example-col-1 {
      height: 300px;
    }
  }

  .example-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }

 

  .example-col-2{
    border-radius: 15px;
    padding: 50px;
  
  }
  @media (max-width: 500px) {
    .example-col-2 {
      padding: 10px;
      margin-top: 20px;
    }
  }

  .example-title {
    color: teal;
    text-transform: normal;
  }
  
  .example-text {
    text-transform: normal;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .example-text {
      text-transform: normal;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 15px;
    }
  }
.form-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 600px;  

  }

  @media screen and (max-width: 768px) {
   .form-container{
    width:85%;
    max-width: 400px;  
   }
  }
  
 

  .url-input{ 
    width: 80%;
    padding-left: 20px;
    font-size: 19px;
    height: 40px;
    border-radius: 5px 0px 0px 5px;
    border:1px solid #3333;
}

@media screen and (max-width: 768px) {
  .url-input{ 
  font-size: 15px;
}
 }

.url-input:focus {
  outline: none;
}

.try-it-button{
    background-color: teal;
    color:#fff;
    width:100px;
    height:44px;
    border: none;
    border-radius:0px 5px 5px 0px;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
   /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
}
  
.center-grid {
    display: flex;
    justify-content: center;
    background-color: whitesmoke;
   
}


.illustration-grid {
    display: grid;
    grid-template-columns: 1fr 0.00003fr 1fr;
    width: 80%;
    align-items: center;
  }

  @media (max-width: 1500px) {
    .illustration-grid {
      display: grid;
    grid-template-columns: 1fr 0.00003fr 1fr;
    width: 80%;
    align-items: center;
    margin-top: 100px;
    }
  }
  
  @media (max-width: 768px) {
    .illustration-grid {
      grid-template-columns: 1fr;
      height: auto;
      width:90%;
      padding-bottom: 50px;
      margin-top: 10px;
    }
  }

  @media (max-width: 500px) {
    .illustration-grid {
      grid-template-columns: 1fr;
      height: auto;
      padding-bottom: 50px;
      margin-top: 50px;
    }
  }

  .sheet-img-col{
    overflow: hidden;
  border-radius: 15px;
  height: 400px;
  }

  @media (max-width: 768px) {
  }

  .sheet-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  }

  .arrow-left-icon-col{
    width: 100px;
    height: 50px;
   
  }

  @media (max-width: 768px) {
    .arrow-left-icon-col {
     display: none;
    }
  }

  .arrow-left-icon{
    width: 100%;
    height: 100%;
    font-size: 20px;
   
  }
/*****Arrow Small Screens***/

  .arrow-down-icon-col{
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  @media (min-width: 768px) {
    .arrow-down-icon-col {
     display: none;
    }
  }

  .arrow-down-icon{
    width: 100px;
    height: 100%;
    font-size: 20px;
  }



  .syntax-highlighter-col{
    overflow: hidden;
    border-radius: 10px;
   
   
  }
  



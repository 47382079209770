.side-navigation {
    width: 100%;
    border-radius: 0 10px 10px 0;
    height: 100vh;
   /* background-color:rgb(18, 21, 33);*/
   background-color: rgb(27, 61, 61);
    color: #fff;
    top: 0; 
    bottom: 0;
    transition: 0.5s;
    position: sticky;
    overflow: hidden;
    display: none; /* Initially hide on small screens */
  }

  .dashboard-logo-wrapper{
    display: flex;
    align-items:flex-start;
    padding: 5px 25px;
    margin-top: 30px;
  }

  .dashboard-logo {
    text-decoration: none;
    color:#fff;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bolder;
    font-style: italic;
  }
  .dashboard-logo:hover{
    text-decoration: underline;
    color:teal;
  }
  
  .toggle-btn {
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #fff;
    padding: 10px;
    display: block; /* Initially show on small screens */
  }
  
  nav {
    padding-top: 60px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
 .sidenav-link {
    padding: 10px 25px;
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    color:#fff;


  }
  
  .sidenav-link:hover {
    background-color: #151414;
    color:teal;
  }

  .focused-sidenav-link{
    background-color: #151414;
    color:teal;
    padding: 10px 25px;
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    
  }
  
  /* Media query for small screens */
  @media screen and (max-width: 600px) {
    .side-navigation {
      display: none; /* Hide on small screens */
    }
  
    .toggle-btn {
      display: block; /* Show toggle button on small screens */
    }
  
    /* Open side navigation when the class is applied */
    .side-navigation.open {
      display: block;
    }
  }
  
  /* Media query for larger screens */
  @media screen and (min-width: 601px) {
    .side-navigation {
      display: block; /* Show on larger screens */
    }
  
    .toggle-btn {
      display: none; /* Hide toggle button on larger screens */
    }
  }

  .logout-button-wrapper{
    cursor: pointer;
    font-size: 19px;
    padding: 10px 25px;
    display: flex;
    justify-content: flex-start;
  }
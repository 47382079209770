.footer {
    background-color: rgb(17, 80, 80);
    color: #fff;
    padding: 10px 20px ;
    padding-bottom: 10px;
    margin-bottom:0px;
   
  }
  
  .footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .footer-section {
   margin-bottom: 10px;
  }
  
  .footer-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section li {
    padding: 5px 0;
  }
  
  .footer-link{
    text-decoration: none;
    display: block;
    width: 100%;
    color:#ffff;
    font-size: 18px;
    font-style: normal;
    font-family: 'Open Sans', sans-serif;
   
  }

  .footer-link:hover{
    text-decoration: underline;
    color: #fff;
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    width:100px;
  }
  
  .social-icons img {
    width: 30px;
    margin-right: 10px;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
  }
  
  @media only screen and (max-width: 768px) {
    .footer-content {
      grid-template-columns: 1fr;
    }
  }

  .footer-logo{
  text-decoration: none;
  color:#fff;
  font-size: 1.3rem;
  font-weight: bolder;
  font-style: italic;
  margin-top: 20px;
  }
.list-item-wrapper{
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    background-color: whitesmoke;
    border: 0.2px solid #333;
    border-radius: 5px;
    padding:10px;
    padding-bottom: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media(min-width: 768px) {
    .list-item-wrapper{
        width:85%;
    }

}

.list-item-header-text-wrapper{
    display: flex;
    width:300px;
}

.list-item-header-text{
    font-size: 19px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    margin-left: 10px;
}

.api-url-wrapper{
display: flex;
align-items: center;
background-color:rgb(250, 249, 251);
padding:10px;
width:80%;
margin-top: 40px;
    
}

@media(max-width: 768px) {
    .api-url-wrapper{
        width:90%;
    }

}


.api-url-title{
    font-size: 19px;
    font-weight: bold;
    margin-left: 10px;
    color: rgb(36, 26, 26);
}

.api-url-link-text {
    text-decoration: none;
    margin-left: 10px;
    font-size: 20px;
    color: #6c5fc7;
    overflow: hidden;
    
}
.api-url-link-text a{
    text-decoration: none;
    margin-left: 10px;
    font-size: 20px;
    color: #6c5fc7;
    overflow: hidden;
    
}

.api-url-link-text a:hover{
    text-decoration:underline
}

.copy-link-button{
    margin-left: 10px;
    width:80px;
    display:flex;
    cursor: pointer;
    padding-top:5px;
    padding-bottom: 5px;
    background-color: transparent;
    border: 0.2px solid #333;
}

.is-empty-message{
    font-size: 19px;
    font-weight: 700;
    margin-left: 10px;
    color: rgb(36, 26, 26);
}



.is-empty-button{
    margin-top: 50px;
    background-color: teal;
    color:#fff;
    width:300px;
    height:50px;
    border:none;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    text-align: center;
}